import React, { useState } from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import "lazysizes"
import clsx from "clsx"
import { GatsbyImage } from "gatsby-plugin-image"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import { ro } from "date-fns/locale"
import { graphql, Link } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import Container from "@material-ui/core/Container"
import ListItem from "@material-ui/core/ListItem"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import MenuItems from "../components/procedures/MenuItems"

import DynamicBanner from "../components/banners/DynamicBanner"
import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import Navigation from "../components/Navigation"
import GeneralContact from "../components/contacts/GeneralContact"
import addToSchema from "../services/addToSchema"
import ContactLocationsCompactCarousel from "../components/carousels/ContactLocationsCompactCarousel"
import ContactPopup from "../components/contacts/ContactPopup"
import SectionTitle from "../components/SectionTitle"
import TeamMembersCarousel from "../components/carousels/TeamMembersCarousel"
import BannerImage from "../components/banners/BannerImage"
import MixedCarousel from "../components/carousels/MixedCarousel"

const useStyles = makeStyles(theme => ({
  image: {
    display: "block",
    maxHeight: 200,
    width: "100%",
  },
  bigAvatar: {
    marginRight: 10,
    width: 60,
    height: 60,
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  title: {
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid",
    wordBreak: "break-word",
  },
  listItem: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
  },
  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },
}))

const ProcedurePage = props => {
  const {
    data: { logo, procedure, teamPage, contactLocations, teamMembers },
    pageContext: { locale, pageSlug, breadcrumbs },
    location,
  } = props

  const classes = useStyles()
  const [selectedContactLocationUid, setSelectedContactLocationUid] = useState(
    "-"
  )
  const [selectedTeamMemberUid, setSelectedTeamMemberUid] = useState("-")

  const [contactModalOpen, setContactModalOpen] = useState(false)
  const members = teamMembers.edges.map(r => r.node)
  const locations = contactLocations.edges.map(r => r.node)

  const onSelectContactLocation = contactLocationUid => {
    setSelectedContactLocationUid(contactLocationUid)
    setContactModalOpen(true)
  }

  const onSelectTeamMember = teamMemberUid => {
    setSelectedTeamMemberUid(teamMemberUid)
    setContactModalOpen(true)
  }

  let schema = procedure.data.schema_json.text || ""

  schema = addToSchema(schema, {
    "@context": "https://schema.org",
    "@type": "Article",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${process.env.GATSBY_BASE_URL}${localizedSlugPrefix(
        procedure
      )}${breadcrumbs.pop()?.path}`,
    },
    "headline": procedure.data.meta_title,
    "image": procedure.data.image.url,
    "author": {
      "@type": "Person",
      "name": procedure.data?.author?.document?.data?.name?.text ?? 'Echipa Monza Ares',
      "url": procedure.data?.author?.document?.uid ? `${process.env.GATSBY_BASE_URL}/echipa/${procedure.data?.author?.document?.uid}/` : `${process.env.GATSBY_BASE_URL}${logo.publicURL}`
    },
    "publisher": {
      "@type": "Organization",
      "name": "Monza Ares",
      "logo": {
        "@type": "ImageObject",
        "url": `${process.env.GATSBY_BASE_URL}${logo.publicURL}`
      }
    },
    "datePublished": procedure.last_publication_date
  })

  const ogp = procedure.data.ogp_tags || ""
  const meta = {
    title: procedure.data.meta_title,
    description: procedure.data.meta_description,
    keywords: procedure.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Typography variant="h1" component="h1" className={classes.title}>
            {procedure.data.title.text}
          </Typography>
          <br />        
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Table className={classes.table} size="small">
                <TableBody>
                  {procedure.data.summary_items.map(row => (
                    <TableRow key={row.key}>
                      <TableCell size="small" padding="none">{row.key}</TableCell>
                      <TableCell size="small" padding="none">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} md={8}>
              {procedure.data.image.gatsbyImageData && (
                <GatsbyImage
                  alt={procedure.data.meta_title}
                  image={procedure.data.image.gatsbyImageData}
                  className={classes.image}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} className="procedureWrapper">
            
            <Grid item xs={12} md={8}>
              <Typography
                component="span"
                variant="small"
                className={classes.date}
              >
                {`Publicat: ${format(parseISO(procedure.first_publication_date), "PPP", {
                  locale: ro,
                })} `}
              </Typography>
              <br />
              <Typography
                component="span"
                variant="small"
                className={classes.date}
              >
                {`Ultima actualizare: ${format(parseISO(procedure.last_publication_date), "PPP", {
                  locale: ro,
                })} `}
              </Typography>
              
              {procedure.data.body1 && (
                <Navigation content={procedure.data.body1} />
              )}
              {procedure.data.body1 &&
                procedure.data.body1.map(slice => (
                  <div key={shortid.generate()}>
                    {slice.slice_type === "repeatable_image_type" && (
                      <div className="sliceRepeatableImageType">
                        <Typography
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.content.html,
                          }}
                        />
                      </div>
                    )}

                    {slice.slice_type === "list" && (
                      <Grid
                        container
                        className={classes.sliceListType}
                        direction="column"
                      >
                        <Grid item>
                          <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                              __html: slice.primary.content.html,
                            }}
                          />
                        </Grid>
                        <Grid item sm={10}>
                          <List disablePadding>
                            {slice.items.map(item => (
                              <ListItem
                                alignItems="flex-start"
                                key={shortid.generate()}
                                className={classes.listItem}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    alt={item.list_item_content}
                                    src={
                                      item.list_item_image.gatsbyImageData
                                        .images.fallback.src
                                    }
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={item.list_item_content}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      </Grid>
                    )}

                    {slice.slice_type === "simple" && (
                      <Typography
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: slice.primary.content.html,
                        }}
                      />
                    )}

                    {slice.slice_type === "team_members" && (
                      <>
                        <Typography
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.content.html,
                          }}
                        />
                        <TeamMembersCarousel
                          teamMembers={members}
                          onSelect={onSelectTeamMember}
                        />
                      </>
                    )}



                    {slice.slice_type === "locations" && locations.length > 0 && (
                      <>
                        <Typography
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.content.html,
                          }}
                        />


                        <ContactLocationsCompactCarousel
                          contactLocations={locations}
                          onSelect={onSelectContactLocation}
                        />
                      </>
                    )}

                    {slice.slice_type === "mixed_carousel" && (
                      <>
                        <Typography
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.content.html,
                          }}
                        />
                        <MixedCarousel items={slice.items.map(item => item.link.document)} />
                      </>
                    )}




                    {slice.slice_type === "dynamic_banner" && (
                      <div className={classes.dynamicBanner}>

                        {slice.items
                          .filter(
                            item =>
                              item.banner_type === null ||
                              item.banner_type === "contact" ||
                              item.banner_type === "promotion" ||
                              item.banner_type === "consultant"
                          )
                          .map(item => (
                            <div key={shortid.generate()}>
                              <DynamicBanner
                                data={item}
                                location={location}
                                locale={locale}
                              />
                            </div>
                          ))}
                      </div>
                    )}

                    {slice.slice_type === "banner_image" &&
                      <BannerImage
                        image={slice.primary.banner_image}
                        url={slice.primary.banner_url}
                        showContact={slice.primary.show_contact_form}
                      />
                    }
                  </div>
                ))}

              {locations.length > 0 &&
                <>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SectionTitle component="h2">
                        Locații MONZA ARES în care se efectuează {procedure.data.title.text}
                      </SectionTitle>
                    </Grid>

                    <Grid item xs={12}>
                      <ContactLocationsCompactCarousel
                        contactLocations={locations}
                        onSelect={onSelectContactLocation}
                      />
                    </Grid>
                  </Grid>
                </>
              }

            </Grid>
            <Grid item xs={12} md={4}>
              <div>
                <MenuItems
                  location={location}
                  locale={locale}
                  slug={pageSlug}
                />
              </div>

              {/* if we have doctors doing this procedure show that section */}
              {members.length > 0 && (
                <div>
                  <Divider className={classes.divider} />
                  <Typography variant="h2" component="h2">
                    Medici care efectuează această procedură:
                  </Typography>

                  <TeamMembersCarousel
                    slidesToShowLg={1}
                    slidesToShowMd={1}
                    slidesToShowSm={1}
                    slidesToShowXs={1}
                    teamMembers={members}
                    onSelect={onSelectTeamMember}
                  />
                </div>
              )}
              <br />

              <div className={`${clsx("contactHolder", classes.wideContact)}`}>
                <GeneralContact location={location} locale={locale} />
              </div>
            </Grid>
          </Grid>
        </Container>
        <ContactPopup
          locale={locale}
          location={location}
          open={contactModalOpen}
          handleClose={() => setContactModalOpen(false)}
          selectedContactLocationUid={selectedContactLocationUid}
          selectedTeamMemberUid={selectedTeamMemberUid}
        />
      </main >
    </>
  )
}

export default ProcedurePage

export const query = graphql`
  query PrismicProcedureBySlug($slug: String!, $locale: String!) {
    logo: file(base: {eq: "logo_blue.png"}) {   
      publicURL    
    }

    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    procedure: prismicProcedure(uid: { eq: $slug }, lang: { eq: $locale }) {
      id
      lang
      last_publication_date
      first_publication_date
      data {
        author {
          document {
            ... on PrismicTeamMember {
              data {
                name {
                  text
                }
              }
              uid
            }
          }
        }
        body1 {
          ... on PrismicProcedureDataBody1List {
            id
            slice_type
            items {
              list_item_content
              list_item_image {
                gatsbyImageData(layout: FIXED, width: 150)
              }
            }
            primary {
              content {
                html
                raw
              }
            }
          }
          ... on PrismicProcedureDataBody1RepeatableImageType {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
          }
          ... on PrismicProcedureDataBody1Simple {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
          }



          ... on PrismicProcedureDataBody1TeamMembers {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
          }


          ... on PrismicProcedureDataBody1Locations {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
          }




          ... on PrismicProcedureDataBody1MixedCarousel {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
            items {
              link {
                document {
                  ... on PrismicProcedure {
                    id
                    uid
                    type
                    data {
                      categories {
                        category {
                          document {
                            ... on PrismicProcedureCategory {
                              id
                              uid
                              data {
                                icon {
                                  alt
                                  gatsbyImageData(layout: FIXED, width: 150)
                                }
                              }
                            }
                          }
                        }
                      }
                      body1 {
                        ... on PrismicProcedureDataBody1Simple {
                          id
                          primary {
                            content {
                              text
                            }
                          }
                        }
                        ... on PrismicProcedureDataBody1RepeatableImageType {
                          id
                          primary {
                            content {
                              text
                            }
                          }
                        }
                      }               
                      title {
                        text
                      }
                    }
                  }


                  ... on PrismicNews {
                    id
                    type
                    uid
                    first_publication_date
                    data {
                      excerpt {
                        text
                      }
                      image {
                        gatsbyImageData(layout: CONSTRAINED, width: 600)
                      }
                      title {
                        text
                      }
                    }
                  }



                  ... on PrismicCondition {
                    id
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      excerpt {
                        text
                      }
                      image {
                        gatsbyImageData(layout: CONSTRAINED, width: 600)
                      }
                    }
                  }



                  ... on PrismicTeamMember {
                    id
                    lang
                    data {
                      name {
                        text
                      }
                      specialisations {
                        specialisation: specialization {
                          document {
                            ... on PrismicSpecialisation {
                              id
                              uid
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      profile_image {
                        alt
                        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
                        url
                      }
                    }
                    uid
                    type
                  }
                }
              }
            }
          }



          ... on PrismicProcedureDataBody1BannerImage {
            id
            slice_type
            primary {
              banner_image {
                gatsbyImageData(layout: FULL_WIDTH)
                alt
              }
              banner_url
              show_contact_form
            }
          }



          ... on PrismicProcedureDataBody1DynamicBanner {
            id
            slice_type
            primary {
              display_duration
            }
            items {
              banner_type
              banner_text {
                html
                text
              }
              banner_text_2 {
                html
              }
              phone
              display_phone
              consultant_image {
                gatsbyImageData(layout: CONSTRAINED, height: 170)
              }
            }
          }
        }

        title {
          text
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        summary_items {
          key
          value
        }
        categories {
          category {
            id
            url
            slug
          }
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }

    teamPage: prismicTeam(lang: { eq: $locale }) {
      uid
    }

    contactLocations: allPrismicContactLocation(
      filter: {
        data: {
          procedures: {
            elemMatch: {
              procedure: {uid: {eq: $slug}, lang: {eq: $locale } }
            }
          }
        }
      }
    ) {
      edges {
        node {
          uid
          lang
          data {
            address {
              html
            }
            coordinates {
              latitude
              longitude
            }
            title {
              text
            }
            city {
              text
            }
            long_title {
              text
            }
            gallery {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }

    teamMembers: allPrismicTeamMember(
      filter: {
        data: {
          procedures: {
            elemMatch: {
              procedure: { uid: { eq: $slug }, lang: { eq: $locale } }
            }
          }
        }
      }
    ) {
      edges {
        node {
          id
          lang
          data {
            name {
              text
            }
            specialisations {
              specialisation: specialization {
                document {
                  ... on PrismicSpecialisation {
                    id
                    uid
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            profile_image {
              alt
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
              url
            }
          }
          uid
        }
      }
    }
  }
`

ProcedurePage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    procedure: PropTypes.object.isRequired,
    teamPage: PropTypes.object.isRequired,
    contactLocations: PropTypes.object.isRequired,
    teamMembers: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    pageSlug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
